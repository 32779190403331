.App {
  text-align: left;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.terminal {
  width: 100%;
  height: 100%;
  flex-grow: 1;
  padding: 0 10px;
  margin: 0 auto;
  font-family: monospace;
  overflow-y: hidden;
  overflow-x: hidden;
  box-sizing: border-box;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.xterm .xterm-viewport {
  /* On OS X this is required in order for the scroll bar to appear fully opaque */
  background-color: transparent;
  overflow-y: scroll;
  cursor: default;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  scrollbar-color: var(--highlight) var(--dark);
  scrollbar-width: thin;
}

.xterm-viewport::-webkit-scrollbar {
  background-color: var(--dark);
  width: 5px;
}

.xterm-viewport::-webkit-scrollbar-thumb {
  background: var(--highlight);
}